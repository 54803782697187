import useRequest from './api/useRequest';
import { listConfig, readConfig, readOptionConfig } from '@src/services/product/config';
import {
	ProductListParamType,
	ProductOptionType,
	ProductsResponse,
	ProductType,
} from '@src/types/product';
import useIdRequest from './api/useIdRequest';

export const useProductList = (params?: ProductListParamType) =>
	useRequest<ProductsResponse>(listConfig(params));

export const useProductRead = () => useIdRequest<ProductType>(readConfig);

export const useProductOptionRead = () =>
	useIdRequest<{ options: ProductOptionType[] }>(readOptionConfig);
