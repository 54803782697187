import React from 'react';
import styled from 'styled-components';

import cssMixin from '@src/style';

import { ThemeType } from '@src/types/theme';
import CateList from './cate-list';

export default function ListHeader({ cateId, setCateId }) {
	return (
		<Wrapper>
			<CateList {...{ cateId, setCateId }} />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	width: 100%;
	height: fit-content; 

	${cssMixin.flexCol}
	margin: 0.24rem 0 0.24rem;

	${({ theme }: { theme: ThemeType }) => theme.tablet`
		padding:0 0.04rem;
		margin: 0.23rem 0 0.27rem;
	`}

	${({ theme }: { theme: ThemeType }) => theme.mobile`
		padding:0 0.04rem;
		margin: 0.16rem 0 0.16rem;
	`}
`;

const Title = styled.p`
	font-size: 24px;
	font-weight: bold;
	${({ theme }: { theme: ThemeType }) => theme.smallTablet`
		font-size: 22px;
	`}
`;
