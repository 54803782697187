import React from 'react';
import styled from 'styled-components';

import Col from '@src/components/atoms/col';
import cssMixin from '@src/style';
import LoadingBar from '@src/components/common/loading-bar';

import { useProductList } from '@src/hooks/product';

export type ListProps = {
	cateId: number;
	ListItem: React.FunctionComponent<any>;
};

function List({ cateId, ListItem }: ListProps) {

    const { data } = useProductList(cateId === 0 ? null : { categoryId: String(cateId) });
	const products = data?.products;

	if (!products) return <LoadingBar />;

	return (
		<Wrapper>
			{products.map((data, index) => (
				<Col key={index} labtopL={3} labtop={4} tablet={6} mobile={4}>
					<ListItem {...data} />
				</Col>
			))}
		</Wrapper>
	);
}

export default React.memo(List);

const Wrapper = styled.div`
	${cssMixin.resRow}
	overflow:visible;
	font-family: Spoqa Han Sans Neo;
`;
