import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '.';

export type ChipProps = {
	className?: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	title?: string;
	selected?: boolean;
};

export default function Chip({ onClick, className, title, selected }: ChipProps) {
	const [touchable, setTouchable] = useState(false);

	return (
		<Wrapper
			type="button"
			className={className}
			onClick={onClick}
			selected={selected}
			onTouchStart={() => {
				setTouchable(true);
			}}
			touchable={touchable}
		>
			{title}
		</Wrapper>
	);
}

const Wrapper = styled.button<{ selected: boolean; touchable: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;

	border-radius: 0.32rem;

	padding: 0.24rem;

	border: 1px solid ${Color.GRAY500};
	background-color: white;
	color: #9b9b9b;
	cursor: pointer;

	height: 0.32rem;
	margin-right: 0.16rem;
	white-space: nowrap;

	font-size: 0.18rem;

	${({ theme }) => theme.breakpoint.mobile`
		font-size: 0.14rem;
		padding: 0 0.12rem;
		height: 0.36rem;
		margin-right: 0.12rem;
	`}

	${props =>
		props.selected &&
		css`
			background-color: #3e4042;
			border-color: transparent;
			color: white;
		`}
	${props =>
		!props.touchable &&
		css`
			&:hover {
				background-color: ${Color.GRAY900};
				border-color: transparent;
				color: white;
			}
		`}
`;
