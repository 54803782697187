import React from 'react';
import styled from 'styled-components';

import SmallProductCardInfo from './info';

import { ThemeType } from '@src/types/theme';
import { ProductType } from '@src/types/product';
import LinkWrapper from '@src/components/molecules/link';
import { Color, Thumbnail, TouchBox } from '@src/components/atoms';

export type SmallProductCardProps = Pick<
	ProductType,
	'thumbnailUrl' | 'title' | 'category' | 'sellMethod' | 'productId' | 'salePrice' | 'originalPrice' | 'nickname'
>;

export default function SmallProductCard({
	thumbnailUrl,
	title,
	category,
	sellMethod,
	productId,
	salePrice,
	originalPrice, 
	nickname,
}: SmallProductCardProps) {
	return (
		<LinkWrapper href="/product/[id]" as={`/product/${productId}`}>
			<TouchBox style={{ borderRadius: '0.04rem' }}>
				<Wrapper>
					<ImageWrapper>
						<Thumbnail						
							src={thumbnailUrl}
							collapse="width"
							setLabel={salePrice === 0}
							lazyLoad
						/>
					</ImageWrapper>
					<SmallProductCardInfo {...{ title, originalPrice, salePrice, nickname }} />
				</Wrapper>
			</TouchBox>
		</LinkWrapper>
	);
}

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	border-radius: 0.04rem;
	overflow: hidden;

	margin-bottom: 0.24rem;

	// :hover {
	// 	box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
	// 	transform: translateY(-3%);
	// 	transition: all 0.3s ease-in-out;
	// }

	${({ theme }: { theme: ThemeType }) => theme.breakpoint.labtop`
		margin-bottom:0.16rem;
		:hover{
			box-shadow:none;
			transform:none;
			trasition:none;
		}
	`}
`;

const ImageWrapper = styled.div`
	border-radius: 0.04rem;
	overflow: hidden;
`;