import CircleLoader from '@src/components/atoms/loader/circle';
import React from 'react';
import styled from 'styled-components';

export default function LoadingBar() {
	return (
		<Wrapper>
			<CircleLoader />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
