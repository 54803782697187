import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Chip from '@src/components/atoms/chip-main';

import { CategoryType, CategoryId, Category } from '@src/types/category';

export default function CateList({ cateId, setCateId }) {
	const cateList = [
		{'productCategoryId' : 1, 'name' : Category[CategoryId[1]]},
		{'productCategoryId' : 2, 'name' : Category[CategoryId[2]]},
		{'productCategoryId' : 4, 'name' : Category[CategoryId[4]]},
		{'productCategoryId' : 5, 'name' : Category[CategoryId[5]]},
	];

	const handleClick = clickedCateId => {
		setCateId(clickedCateId);
	};

	const checkIsSelected = checkCateId => {
		return cateId && (cateId===checkCateId);
	};

	return (
		<Wrapper>
			<Chip onClick={handleClick.bind(this, 0)} title="전체" selected={cateId === 0} />
			{cateList &&
				cateList.map((v: CategoryType) => (
					<Chip
						key={v.productCategoryId}
						onClick={handleClick.bind(this, v.productCategoryId)}
						title={v.name}
						selected={checkIsSelected(v.productCategoryId)}
					/>
				))}
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
