import React from 'react';
import styled from 'styled-components';

import { P } from '@src/components/atoms';

import Price from '@src/components/common/card/price';

export default function SmallProductCardInfo({ title, originalPrice, salePrice, nickname }) {
	return (
		<Wrapper>
			<P width="100%" fontWeight="500" ellipsis level={1}>
				{title}
			</P>
			<PriceWrapper>
				<Price {...{ originalPrice, salePrice, nickname }} fontSize={18} />
			</PriceWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: fit-content;

	padding-top: 0.2rem;
	padding-bottom: 0.16rem;
`;

const PriceWrapper = styled.div`
	margin-top:0.12rem;
`;