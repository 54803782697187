import useSWR, { ConfigInterface, responseInterface } from 'swr';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import Axios from 'axios';
import { useState, useEffect } from 'react';

export type GetRequest = AxiosRequestConfig | null;

interface Return<Data, Error>
	extends Pick<
		responseInterface<AxiosResponse<Data>, AxiosError<Error>>,
		'isValidating' | 'revalidate' | 'error' | 'mutate'
	> {
	data: Data | undefined;
	response: AxiosResponse<Data> | undefined;
}

export interface Config<Data = unknown, Error = unknown>
	extends Omit<ConfigInterface<AxiosResponse<Data>, AxiosError<Error>>, 'initialData'> {
	initialData?: Data;
}

export default function useRequest<Data = unknown, Error = unknown>(
	request: GetRequest,
	{ initialData, ...config }: Config<Data, Error> = {},
): Return<Data, Error> {
	const { data: response, error, isValidating, revalidate, mutate } = useSWR<
		AxiosResponse<Data>,
		AxiosError<Error>
	>(
		request && JSON.stringify(request),
		/**
		 * NOTE: Typescript thinks `request` can be `null` here, but the fetcher
		 * function is actually only called by `useSWR` when it isn't.
		 */
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		() => axios(request!),
		{
			...config,
			initialData: initialData && {
				status: 200,
				statusText: 'InitialData',
				config: request!,
				headers: {},
				data: initialData,
			},
		},
	);

	return {
		data: response && response.data,
		response,
		error,
		isValidating,
		revalidate,
		mutate,
	};
}

export function useAxiosRequest<T>(config: AxiosRequestConfig) {
	const [data, setData] = useState<T>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const res = await Axios(config);
			setData(res.data);
			setLoading(false);
		} catch (error) {
			setError(error);
			if (error.response) {
				// 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			} else if (error.request) {
				// 요청이 이루어 졌으나 응답을 받지 못했습니다.
				// `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
				// Node.js의 http.ClientRequest 인스턴스입니다.
				console.log(error.request);
			} else {
				// 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
				console.log('Error', error.message);
			}
			console.log(error.config);
		}
	};

	return { data, loading, error };
}
