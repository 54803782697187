import React from 'react';
import styled, { keyframes } from 'styled-components';

export type CircleLoaderProps = {
	size?: number;
	color?: string;
};

export default function CircleLoader({ size = 30, color = 'black' }: CircleLoaderProps) {
	return <Loading size={size} color={color} />;
}

const load1 = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const Loading = styled.div<{ size: number; color: string }>`
	width: ${props => props.size + 'px'};
	height: ${props => props.size + 'px'};
	border-radius: 999px;
	border-top: 3px solid ${props => props.color};
	border-right: 3px solid ${props => props.color};
	border-bottom: 3px solid ${props => props.color};
	border-left: 3px solid #ffffff;

	position: relative;
	animation: ${load1} 1.2s infinite linear;
`;
