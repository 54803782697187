import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import cssMixin from '@src/style';
import SectionHeader from '@src/components/common/section/header';
// import List from '@src/components/common/list';
import SmallProductCard from '@src/components/common/card/product/small';

import ListHeader from './header';
import List from './list';

export default function NewlyUpdated() {
	const [cateId, setCateId] = useState(0);
	return (
		<Background>
			<Container>
				<SectionHeader
					title="어떤 자료가 필요하신가요?"
				/>
				<ListHeader {...{ cateId, setCateId }} />
				<List cateId={cateId} ListItem={SmallProductCard} />
			</Container>
		</Background>
	);
}

const Container = styled.div`
	${cssMixin.container};
`;
const Background = styled.div`
	width: 100%;
	height: fit-content;
	padding-bottom: 1.3rem;
	${({ theme }) => theme.breakpoint.mobile`
		padding-bottom:0.6rem;
	`}
`;
