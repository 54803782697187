import Axios, { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export default function useIdRequest<T>(config: (id) => AxiosRequestConfig) {
	const [data, setData] = useState<T>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();
	const router = useRouter();
	const {
		query: { id },
	} = router;

	useEffect(() => {
		if (!id) return;
		getData();
	}, [id]);

	const getData = async () => {
		setLoading(true);
		try {
			const res = await Axios(config(id));
			setData(res.data);
			setLoading(false);
		} catch (error) {
			setError(error);
			if (error.response) {
				// 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			} else if (error.request) {
				// 요청이 이루어 졌으나 응답을 받지 못했습니다.
				// `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
				// Node.js의 http.ClientRequest 인스턴스입니다.
				console.log(error.request);
			} else {
				// 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
				console.log('Error', error.message);
			}
			console.log(error.config);
		}
	};

	return { data, loading, error };
}
