export type CategoryType = {
	productCategoryId?: number;
	parentId?: number;
	name: Category;
	level?: number;
};

export type CategoryItemType = {
	Icon: React.FunctionComponent<any>;
	label: string;
	id: number;
};

export enum CategoryId {
	BUSINESS = 1,
	JOB = 2,
	SALE = 6,
	WEALTH = 4,
	COLLEGE = 5,
}

export enum Category {
	WEALTH = '재테크/부업',
	JOB = '취업',
	BUSINESS = '창업',
	COLLEGE = '입시',
	SALE = '지금 할인',
}

export type Tag = {
	tagId: number;
	name: string;
	categoryId: number;
};
